<template>
  <el-popover
      v-bind="$attrs"
      v-model="showPopper"
      @input="value=>$emit('input', value)"
      popper-class="ys-popper"
      :popper-options="{
                    boundariesElement: 'viewport',
                    removeOnDestroy: true,
                  }">
    <slot slot="default"></slot>
    <div class="reference" slot="reference">
      <slot name="reference"></slot>
    </div>
  </el-popover>
</template>

<script>

export default {
  name: "YsPopover",
  props: {
    value: Boolean,
  },
  data() {
    return {
      showPopper: false,
      referenceElm: null,
    }
  },
  watch: {
    value: {
      handler() {
        this.showPopper = this.value;
      },
      immediate: true,
    }
  },
  methods: {

  },
  mounted() {
  },
  destroyed() {
  }
}
</script>

<style scoped>

.reference {
  cursor: pointer;
}

.reference:hover {
  filter: brightness(95%);
}

</style>
<style>
.ys-popper.el-popper[x-placement^=left] .popper__arrow {
  display: none;
}

.ys-popper.el-popper[x-placement^=right] .popper__arrow {
  display: none;
}

.ys-popper.el-popper[x-placement^=bottom] .popper__arrow {
  display: none;
}

.ys-popper.el-popper[x-placement^=top] .popper__arrow {
  display: none;
}

.ys-popper.el-popper {
  border-radius: 16px;
  padding: 16px;
}

</style>