<template>
  <div style="position: relative" @mousedown="handleMouseDown" @mousemove="handleMouseMove">
    <div class="mask" v-if="touchedIndex >= 0"></div>
    <div ref="list"
         style="position: relative; user-select: none;">
      <div class="divider" ref="divider0" :data-index="0" v-if="touchedIndex >= 0">
        <transition :name="transitionDisabled ? 'slide-none' : 'slide-fade'">
          <div class="target-region" v-if="targetIndex == 0"
               :style="`width: ${touchedItemWidth}px; height: ${touchedItemHeight}px;`"></div>
        </transition>
      </div>
      <div v-for="(dataItem, index) in value" :key="dataItem[dataKey]">
        <div ref="items" class="item" :class="{touched: touchedIndex == index}" :data-index="index">
          <div class="item-content layout-vertical"
               :class="{touchable: !disabled, touched: touchedIndex == index, 'untouched': touchedIndex >= 0 && touchedIndex != index}"
               @mousedown="handleItemMouseDown(index)"
               @mouseup="handleItemMouseUp(index)"
               :style="`left: ${touchOffset.x}px; top: ${touchOffset.y}px; ${touchedIndex == index ? 'width:' + touchedItemWidth + 'px; height: ' + touchedItemHeight + 'px;' : ''}`">
            <div :style="`pointer-events: ${disabled ? 'auto' : 'none'}`">
              <slot :data="dataItem"></slot>
            </div>
          </div>
        </div>
        <div class="divider" ref="dividers" :data-index="index + 1" v-if="touchedIndex >= 0">
          <transition :name="transitionDisabled ? 'slide-none' : 'slide-fade'">
            <div class="target-region" v-if="targetIndex == index + 1"
                 :style="`width: ${touchedItemWidth}px; height: ${touchedItemHeight}px;`"></div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "YsMovableListView",
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    dataKey: {
      type: String,
      default: 'id'
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      touchedIndex: -1,
      touchStart: {x: 0, y: 0},
      offsetStart: {x: 0, y: 0},
      touchOffset: {x: 0, y: 0},
      targetIndex: -1,
      touchedItemWidth: 0,
      touchedItemHeight: 0,
      transitionDisabled: true,
    }
  },
  methods: {
    handleMouseDown(e) {
      if (this.disabled) return;
      this.touchStart = {x: e.x, y: e.y};
    },
    handleMouseMove(e) {
      if (this.disabled) return;
      if (this.touchedIndex >= 0) {
        let touchOffset = {
          x: e.x - this.touchStart.x + this.offsetStart.x,
          y: e.y - this.touchStart.y + this.offsetStart.y
        };

        if (touchOffset.x == this.touchOffset.x && touchOffset.y == this.touchOffset.y) return;
        this.touchOffset = touchOffset;

        let dividers = [this.$refs.divider0].concat(this.$refs.dividers);

        dividers.sort((a, b) => {
          return a.dataset.index - b.dataset.index;
        });
        let dividerCount = dividers.length;
        let targetIndex = this.targetIndex;
        let offsetY = e.y - this.$refs.list.getBoundingClientRect().top;
        for (let n = 0; n < dividerCount - 1; n++) {
          let divider1 = dividers[n].offsetTop;
          let divider2 = dividers[n + 1].offsetTop + dividers[n + 1].getBoundingClientRect().height;
          if (dividers[n].offsetTop - dividers[n + 1].offsetTop == 0) {
            continue;
          }
          let itemHeight = divider2 - divider1;
          if (offsetY >= divider1 && offsetY <= divider2) {
            if (offsetY < divider1 + itemHeight / 3) {
              if (this.touchedIndex == 0) {
                targetIndex = n >= 1 ? n : 1;
              } else {
                targetIndex = n;
              }
            } else if (offsetY > divider2 - itemHeight / 3) {
              targetIndex = n + 1;
            }
            break;
          }
        }
        this.targetIndex = targetIndex;
      }
    },
    handleMouseUp() {
      if (this.touchedIndex >= 0) {
        let touchedIndex = this.touchedIndex;
        let targetIndex = this.targetIndex;
        this.touchOffset = {x: 0, y: 0};
        this.targetIndex = -2;
        this.touchedIndex = -1;
        if (targetIndex != touchedIndex) {
          this.$emit('sorted', {from: touchedIndex, to: targetIndex})
        }
      }
    },
    handleItemMouseDown(index) {
      if (this.disabled) return;
      if (this.touchedIndex >= 0) return;
      let itemView = null;
      for (let tmp of this.$refs.items) {
        if (tmp.dataset.index == index) {
          itemView = tmp;
          break;
        }
      }

      this.touchedItemWidth = itemView.getBoundingClientRect().width;
      this.touchedItemHeight = itemView.getBoundingClientRect().height;

      this.offsetStart = {x: itemView.offsetLeft, y: itemView.offsetTop};
      this.touchOffset = {x: itemView.offsetLeft, y: itemView.offsetTop};

      this.transitionDisabled = true;
      this.touchedIndex = index;
      this.targetIndex = index + 1;

      this.$nextTick(() => {
        this.transitionDisabled = false;
      });
    },
    handleItemMouseUp() {
      this.transitionDisabled = false;
    },
  },
  mounted() {
    window.addEventListener('mouseup', this.handleMouseUp);
  },
  destroyed() {
    window.removeEventListener('mouseup', this.handleMouseUp);
  }
}
</script>

<style scoped>

.list.touched {
  cursor: grabbing;
}

.divider {
  height: auto;
}

.item.touched {
  height: 0px;
  max-height: 0px;
}

.item-content {
  user-select: none;
}

.item-content.touchable {
  cursor: grab;
}

.item-content.touched {
  position: absolute;
  box-shadow: 0 0 4px #686868;
  border-radius: 16px;
  overflow: hidden;
  z-index: 99999;
  cursor: grabbing;
}

.item-content.untouched {
  opacity: 0.9;
}

.target-region {
  border: dashed 2px #409eff;
  height: 30px;
  box-sizing: border-box;
  border-radius: 16px;
  background-color: #f1f1f1;
}

.mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 0;
}


.slide-fade-enter-active {
  transition: all .2s ease;
}

.slide-fade-enter {
  height: 0 !important;
  max-height: 0;
  opacity: 0;
}

.slide-fade-leave-active {
  transition: all .2s ease;
}

.slide-fade-leave {
  opacity: 1;
  border: none;
  height: auto;
  background-color: #f1f1f1;
}

.slide-fade-leave-to {
  opacity: 0;
  border: none;
  height: 0 !important;
  background-color: #f1f1f1;
}

</style>