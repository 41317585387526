<template>
  <div>

    <div class="buttons padding font-align-left">
      <div class="padding-vertical-small font-color-secondary">button</div>
      <div class="margin-top">
        <span>large</span>
        <ys-button size="large" type="primary">主要按钮</ys-button>
        <ys-button size="large" type="secondary">次要按钮</ys-button>
        <ys-button size="large" type="primary" lighting>发光按钮</ys-button>
        <ys-button size="large" type="secondary" lighting>发光按钮</ys-button>
      </div>
      <div class="margin-top">
        <span>normal</span>
        <ys-button size="normal" type="primary">主要按钮</ys-button>
        <ys-button size="normal" type="secondary">次要按钮</ys-button>
        <ys-button size="normal" type="primary" lighting>发光按钮</ys-button>
        <ys-button size="normal" type="secondary" lighting>发光按钮</ys-button>
      </div>
      <div class="margin-top">
        <span>disabled</span>
        <ys-button size="normal" type="primary" disabled>主要按钮</ys-button>
        <ys-button size="normal" type="secondary" disabled>次要按钮</ys-button>
        <ys-button size="normal" type="primary" lighting disabled>发光按钮</ys-button>
        <ys-button size="normal" type="secondary" lighting disabled>发光按钮</ys-button>
      </div>
    </div>
    <div class="inputs padding font-align-left">
      <div class="padding-vertical-small font-color-secondary">input</div>
      <div class="margin-top">
        <span>large</span>
        <ys-input size="large" placeholder="输入框" maxlength="20" prefix-icon="el-icon-search"></ys-input>
        <ys-input size="large" placeholder="输入框" maxlength="20" :dark="false"></ys-input>
        <ys-input size="large" placeholder="输入框" maxlength="20" hollow></ys-input>
        <ys-input size="large" placeholder="输入框" maxlength="20" :dark="false" hollow></ys-input>
      </div>
      <div class="margin-top">
        <span>normal</span>
        <ys-input size="normal" placeholder="输入框" maxlength="20" prefix-icon="el-icon-search"></ys-input>
        <ys-input size="normal" placeholder="输入框" maxlength="20" :dark="false">
        </ys-input>
        <ys-input size="normal" placeholder="输入框" maxlength="20" hollow></ys-input>
        <ys-input size="normal" placeholder="输入框" maxlength="20" :dark="false" hollow></ys-input>
      </div>
      <div class="margin-top">
        <span>disabled</span>
        <ys-input size="normal" placeholder="输入框" maxlength="20" disabled></ys-input>
        <ys-input size="normal" placeholder="输入框" maxlength="20" :dark="false" disabled></ys-input>
        <ys-input size="normal" placeholder="输入框" maxlength="20" disabled hollow></ys-input>
        <ys-input size="normal" placeholder="输入框" maxlength="20" :dark="false" disabled hollow></ys-input>
      </div>
    </div>
    <div class="textareas padding font-align-left">
      <div class="padding-vertical-small font-color-secondary">textarea</div>
      <ys-textarea placeholder="请输入" autosize class="textarea"></ys-textarea>
      <ys-textarea placeholder="请输入" :dark="false" class="textarea margin-top-small" autosize></ys-textarea>
      <ys-textarea placeholder="请输入" :dark="false" class="textarea margin-top-small" disabled autosize></ys-textarea>
    </div>
    <div class="popover padding font-align-left">
      <div class="padding-vertical-small font-color-secondary">popover</div>
      <ys-popover content="abc">
        <ys-button slot="reference">click me</ys-button>
      </ys-popover>
    </div>
    <div class="dropdown padding font-align-left">
      <div class="padding-vertical-small font-color-secondary">dropdown</div>
      <ys-dropdown>
        <ys-button>click me</ys-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>反对法</el-dropdown-item>
          <el-dropdown-item>范德萨</el-dropdown-item>
        </el-dropdown-menu>
      </ys-dropdown>
    </div>
    <div class="dropdown padding font-align-left" style="padding-left: 100px;">
      <div>
        <div>
          <ys-movable>
            <div class="layout-vertical layout-left">
              <ys-movable-item>
                <ys-movable class="layout-middle" @move="handleMoved">
                  <div class="layout-vertical layout-left">
                    <ys-button>测试按钮</ys-button>
                    <ys-movable-item tag="group" class="margin-top-small">
                      <div class="card padding-vertical-small">
                        <div class="padding-horizontal font-color-secondary">group1</div>
                        <div class="layout-vertical layout-left margin-bottom">
                          <ys-movable-item tag="abc">
                            <div class="padding movable-item" style="background-color: white;">abc</div>
                          </ys-movable-item>
                          <ys-movable-item tag="opq">
                            <div class="padding movable-item" style="background-color: white;">opq</div>
                          </ys-movable-item>
                        </div>
                      </div>
                    </ys-movable-item>
                    <ys-movable-item class="margin-top-small">
                      <div class="card padding-vertical-small layout-vertical layout-left">
                        <div class="padding-horizontal font-color-secondary">group2</div>
                        <ys-movable-item tag="efg" class="margin-top margin-top-small">
                          <div class="padding movable-item" style="background-color: white;">efg</div>
                        </ys-movable-item>
                        <ys-movable-item tag="hij">
                          <div class="padding movable-item" style="background-color: white;">hij</div>
                        </ys-movable-item>
                        <ys-movable-item tag="lmn">
                          <div class="padding movable-item" style="background-color: white;">lmnopqrst</div>
                        </ys-movable-item>
                      </div>
                    </ys-movable-item>
                    <ys-movable-item tag="efg" class="margin-top margin-top-small">
                      <div class="padding movable-item" style="background-color: white;">uvw</div>
                    </ys-movable-item>
                    <ys-movable-item tag="hij">
                      <div class="padding movable-item" style="background-color: white;">xyz</div>
                    </ys-movable-item>
                    <ys-movable-item tag="lmn">
                      <div class="padding movable-item" style="background-color: white;">ooo</div>
                    </ys-movable-item>
                  </div>
                </ys-movable>
              </ys-movable-item>
              <div class="layout-vertical layout-left">
                <ys-movable-item tag="hij">
                  <div class="padding movable-item" style="background-color: white;">other1</div>
                </ys-movable-item>
                <ys-movable-item tag="lmn">
                  <div class="padding movable-item" style="background-color: white;">other2</div>
                </ys-movable-item>
              </div>
            </div>
          </ys-movable>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import YsButton from "../../components/wedigets/YsButton";
import YsInput from "../../components/wedigets/YsInput";
import YsTextarea from "../../components/wedigets/YsTextarea";
import YsPopover from "../../components/wedigets/YsPopover";
import YsDropdown from "../../components/wedigets/YsDropdown";
import YsMovableListView from "../../components/wedigets/YsMovableListView";
import YsMovableItem from "../../components/wedigets/YsMovableItem";
import YsMovable from "../../components/wedigets/YsMovable";

export default {
  name: "WedgetTestPage",
  components: {YsMovable, YsMovableItem, YsMovableListView, YsDropdown, YsPopover, YsTextarea, YsInput, YsButton},
  data() {
    return {
      value: null,
      list: [{id: 1, name: 'a'}, {id: 2, name: '2'}]
    }
  },
  methods: {
    handleMoved(e) {
      console.log(JSON.stringify(e));
    }
  }
}
</script>

<style scoped>
@import "../../assets/css/style.css";

.textarea {
  width: 320px;
}

.movable-item {
  width: 320px;
}

</style>